import React from 'react';
import {
  Background,
  Container,
  Description,
  LogoImage,
  LogoImageContainer,
  MainContent,
  ReasonColumn,
  ReasonContainer,
  ReasonImgContainer,
  ReasonItem,
  ReasonText,
  Title,
  Wrapper
} from './styles';
import whyweImg from '../../images/whywe_logo.png';
import logo1Img from '../../images/whywe/logo1.png';
import logo2Img from '../../images/whywe/logo2.png';
import logo3Img from '../../images/whywe/logo3.png';
import logo4Img from '../../images/whywe/logo4.png';
import logo5Img from '../../images/whywe/logo5.png';
import logo6Img from '../../images/whywe/logo6.png';

const WhyShouldBeYourChoiceSection = () => {
  const reasons = [
    { text: "The Industry’s Best Lead Times", logo: logo1Img },
    { text: "No-Cost Delivery & Installation", logo: logo2Img },
    { text: "Easy Payment Options", logo: logo3Img },
    { text: "Proven Customer Satisfaction", logo: logo4Img },
    { text: "A Unique Blend of Quality and Affordability", logo: logo5Img },
    { text: "Hassle Free Buying Experience", logo: logo6Img }
  ]

  return (
    <Wrapper>
      <Container>
        <Background>why we</Background>
        <MainContent>
          <LogoImageContainer>
            <LogoImage
              alt="Premier logo"
              src={whyweImg}
            />
          </LogoImageContainer>

          <div className="content">
            <Title>Why Premier Metal Structures should be your <span>#1</span> Choice?</Title>
            <Description>We’re proud to be a leading provider with years of experience under our belt and customer satisfaction at the core of what we do. When you work with us, you get: </Description>
          </div>
        </MainContent>
        <ReasonContainer>
          {reasons.map((reason, i) => (
            <ReasonItem key={i}>
              <div align="right">
                <ReasonImgContainer>
                  <img src={reason.logo} alt={reason.text} />
                </ReasonImgContainer>
              </div>
              <ReasonText>{reason.text}</ReasonText>
            </ReasonItem>
          ))}
        </ReasonContainer>
      </Container>
    </Wrapper>
  )
}

export default WhyShouldBeYourChoiceSection;
import styled, { css } from 'styled-components';
import BreakpointDown from '../Media/BreakpointDown';

export const Wrapper = styled.div`
    position: relative;
`

export const Container = styled.div`
    margin: auto;
    /* padding-top: 100px; */
    width: 1380px;
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    margin-bottom: 50px;

    @media (max-width: 1692px) {
        border: 0;
    }

    @media (max-width: 1380px) {
        width: 100%
    }
`

const verticalTxt = css`
    font-family: Roboto;
    font-size: 200px;
    font-weight: 600;
    line-height: 155px;
    letter-spacing: 0.05em;
    text-align: center;
    opacity: 0.08;
    color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    text-transform: uppercase;

    writing-mode: vertical-rl;
    text-orientation: sideways;
`

export const LeftTopSellingTitle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    ${verticalTxt}
    transform: rotate(180deg);

    @media (max-width: 1692px) {
        display: none;
    }
`

export const RightTopSellingTitle = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    ${verticalTxt}
    @media (max-width: 1692px) {
        display: none;
    }
`

export const CardFormContainer = styled.div`
    margin: auto;
    width: 1380px;

    @media (max-width: 1692px) {
        border: 0;
    }

    @media (max-width: 1380px) {
        width: 100%
    }
`

export const CardHorizontal = styled.div`
	display: flex;
	flex-wrap: wrap;
	background: #fff;
	border:1px solid #ddd;
	width:100%;
    position: relative;	
    z-index: 10;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
`

export const CardBody = styled.div`
	padding: 30px;

	${BreakpointDown.lg`
		padding: 24px;
	`}
    ${BreakpointDown.sm`
		padding: 18px;
	`}

	width:100%;
	form {
		display:flex;
		flex-wrap:wrap;		
		@media(min-width:768px){
			margin:0 -20px;
		}
	}
	.form-group-bordered {
		position: relative;
		width: 100%;
		textarea.form-control{
			height:42px;
		}
		@media(min-width:768px){
			padding:0 20px;
			flex:0 0 50%;
			max-width: 50%;

			&.full-row {
				max-width: 100%;
				flex: 1;
			}
			.control-label{
				left:20px;
			}
		}
	}
	.form-action{
		position: relative;
		width: 100%;
		@media(min-width:768px){
			padding:0 20px;
		}
	}
	
	& .form-action{
		text-align: center;
		& .btn{
			width: 179px;
			height: 50px;

			span {
				font-family: Roboto;
				font-size: 20px;
				font-weight: 600;
				line-height: 23.44px;
				text-align: center;
			}
		}
	}
`

export const CardTitle = styled.h3`
	font-family: Roboto;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	color: #101828;
	margin-bottom:30px;

    ${BreakpointDown.lg`
		font-size: 20px;
		line-height: 20px;
	`}
    ${BreakpointDown.sm`
		font-size: 16px;
		line-height: 16px;
	`}
`
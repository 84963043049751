import styled from 'styled-components'
import BreakpointDown from '../Media/BreakpointDown'

export const HeroBanner = styled.section`
  position: relative;	
  background-color:${props => props.bgColors};
  height:${props => props.Height}; 
  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after{		
		content: "";
		display:${props => (props.bgAfter ? 'block' : 'none')};
		position: absolute;				
		top:${props => props.topAfter};
		left:${props => props.leftAfter};
		right:${props => props.rightAfter};
		background-color:${props => props.bgAfter};
		width:${props => props.widthAfter};
		height:${props => props.HeightAfter};
	}
`

HeroBanner.defaultProps = {
  bgColors: "#000",
  topAfter:"0",
	leftAfter:"0",
	widthAfter:"100%",
	HeightAfter:"100%"
}

export const HeroCaption = styled.div`
  position:relative;
  z-index:1;
  width:100%;
  text-align:${props => props.textAlign};  
  padding:90px 20px 30px 20px;
  @media (min-width: 768px) {
    padding:100px 20px 40px 20px;
  }
  @media (min-width: 992px) {
    padding:120px 30px 60px 30px;
  }
  @media (min-width: 1200px) {
    padding:180px 30px 80px 30px;
  }
  @media (min-width: 1600px) {
    padding:190px 30px 90px 30px;
  }
`
HeroCaption.defaultProps = { 
  textAlign: "center",   
}


export const HeroTitle = styled.h1`
    font-family: Roboto;
    text-align: center;
    color: white;
    text-transform: uppercase;
	
    font-size: 80px;
    line-height: 112px; 
    font-weight: 800;
	${BreakpointDown.xl`
		font-size: 64px;
		line-height: 90px; 
	`}

    ${BreakpointDown.lg`
		font-size: 52px;
		line-height: 72px; 
	`}
    ${BreakpointDown.md`
		font-size: 40px;
		line-height: 56px; 
	`}
    ${BreakpointDown.sm`
        font-size: 24px;
        line-height: 32px; 
	`}
`

export const HeroSubTitle = styled.div`
    font-family: Roboto;
    text-align: center;
    color: #00A21E;

    font-size: 32px;
    line-height: 24px;
	font-weight: 500;
	${BreakpointDown.xl`
		font-size: 24px;
		line-height: 24px;
	`}

    ${BreakpointDown.lg`
		font-size: 20px;
		line-height: 18px;
	`}
    ${BreakpointDown.md`
		font-size: 14px;
		line-height: 18px;
	`}
    ${BreakpointDown.sm`
		font-size: 12px;
		line-height: 18px;
	`}
`
export const HeroImage = styled.img`
	width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`
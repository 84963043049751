import React from 'react';
import {
  HeroBanner,
  HeroCaption,
  HeroSubTitle,
  HeroTitle,
  HeroImage
} from './styles';
import heroImg from '../../images/metal-builds-hero.png';


const PPCLandingHeroSection = () => {
  return (
    <HeroBanner bgAfter="rgba(21, 21, 28, 0.92)" widthAfter="100%" HeightAfter="100%">
      <HeroImage alt="Get More for Less: Premium Buildings at Affordable Rates" src={heroImg}/>
      <HeroCaption>
        <HeroTitle>Need a Metal Building?</HeroTitle>
        <HeroSubTitle>Get More for Less: Premium Buildings at Affordable Rates</HeroSubTitle>
      </HeroCaption>
    </HeroBanner>
  )
}

export default PPCLandingHeroSection
import styled from 'styled-components';
import BreakpointDown from '../Media/BreakpointDown';

export const Wrapper = styled.div`
  margin: 64px 0;
`

export const TitleSection = styled.div`
  position: relative;
`

export const TitleBack = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-family: Roboto;
  font-weight: 600;
  font-size: 200px;
  line-height: 234.38px;
  letter-spacing: 0.1em;
  text-align: center;
  color: white;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  opacity: 0.08;

  ${BreakpointDown.xl`
    font-size: 160px;
    line-height: 192px;
  `}
  ${BreakpointDown.lg`
    font-size: 120px;
    line-height: 144px;
  `}
  ${BreakpointDown.md`
    font-size: 100px;
    line-height: 120px;
  `}
  ${BreakpointDown.sm`
    font-size: 60px;
    line-height: 96px;
  `}
`

export const Title = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Roboto;
  font-weight: 800;
  font-size: 44px;
  line-height: 51.56px;
  text-align: center;
  color: #101828;

  ${BreakpointDown.xl`
    font-size: 36px;
    line-height: 51.56px;
  `}
  ${BreakpointDown.lg`
    font-size: 30px;
    line-height: 51.56px;
  `}
  ${BreakpointDown.md`
    font-size: 24px;
    line-height: 51.56px;
  `}
  ${BreakpointDown.sm`
    font-size: 20px;
    line-height: 24px;
  `}
`

export const ContactUsSection = styled.div`
  ${BreakpointDown.xml`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${BreakpointDown.md`
    margin: 0;
  `}
`

export const ContactUs = styled.div`
  width: 1170px;
  padding-top: 28px;
  padding-left: 60px;
  padding-right: 44px;
  display: flex;
  margin: auto;

  background: white;
  box-shadow: 10px 10px 0px 0px #101828B2;

  ${BreakpointDown.xml`
    width: 100%
  `}

  ${BreakpointDown.xml`
    box-shadow: unset;
  `}
`

export const ContactUsLogo = styled.img`
  width: 398px;
  height: 487px;
  margin-right: 72px;

  ${BreakpointDown.lg`
    display: none;
  `}
`

export const ContactUsForm = styled.div`
  flex: 1;

  ${BreakpointDown.lg`
    padding-bottom: 50px;
  `}

  form {
		display: flex;
		flex-wrap: wrap;		
		@media(min-width: 768px){
			margin: 0 -20px;
		}
	}

	.form-group-bordered {
    margin-bottom: 32px;
		position: relative;
		width: 100%;
		textarea.form-control{
			height: 80px;
		}
		@media(min-width:768px){
			padding:0 20px;
			flex:0 0 50%;
			max-width: 50%;

			&.full-row {
				max-width: 100%;
				flex: 1;
			}
			.control-label{
				left:20px;
			}
		}
	}
	.form-action{
		position: relative;
		width: 100%;
		@media(min-width:768px){
			padding:0 20px;
		}
	}
	
	& .form-action{
		margin-top: 28px;
		& .btn{
			width: 179px;
			height: 50px;

			span {
				font-family: Roboto;
				font-size: 20px;
				font-weight: 600;
				line-height: 23.44px;
				text-align: center;
			}
		}
	}
`

export const FormDescription = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #101828;
  margin: 0;
  margin-bottom: 30px;
`
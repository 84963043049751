import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PPCLandingHeroSection from '../components/PPCLandingHeroSection';
import PreviousMetalBuildingsSection from '../components/PreviousMetalBuildings';
import WhyShouldBeYourChoiceSection from '../components/WhyShouldBeYourChoice';
import FinanceOptionSection from '../components/FinanceOptions';
import PPCContactUsSection from '../components/PPCContactUsSection';
import SellingProductionSection from '../components/SellingProduct';

const MetalBuildingsPage = ({ data, location }) => {
  const productSectionData = data.contentfulHome.productSection
  const products = data.allContentfulProduct
  const featuredProducts = data.featuredProducts.edges;

  return (
    <Layout location={location} noQuote>
      <SEO
        title="Metal Buildings"
        description="seo description"
      />

      <PPCLandingHeroSection location={location} />
      
      <SellingProductionSection
        productSectionData={productSectionData}
        products={products}
        location={location}
      />

      <PreviousMetalBuildingsSection products={featuredProducts} />
      <WhyShouldBeYourChoiceSection />
      <FinanceOptionSection />
      <PPCContactUsSection location={location} />
    </Layout>
  )
}

export default MetalBuildingsPage;

export const pageQuery = graphql`
  query MetalBuildingsPageQuery {
    contentfulHome {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroSection {
        sectionTitle
        subTitle
        sectionButtonLink
        sectionButtonText
        sectionImages {
          fluid(maxHeight: 600) {
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
            base64
          }
        }
        mobileImage {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      categorySection {
        sectionTitle
        sectionDescription {
          sectionDescription
        }
      }
      introSection {
        sectionTitle
        subTitle
        sectionDescription {
          id
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        features {
          feature
          title
        }
        sectionImages {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          title
          file {
            url
          }
        }
        sectionButtonText
        sectionButtonLink
      }
      productSection {
        sectionTitle
        subTitle
        sectionDescription {
          sectionDescription
        }
        sectionButtonText
        sectionButtonLink
      }
      ctaSection {
        sectionTitle
        subTitle
        sectionButtonText
        sectionButtonLink
      }
      financeSection {
        sectionTitle
        subTitle
        sectionDescription {
          sectionDescription
        }
        sectionImages {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        features {
          content
        }
        sectionButtonText
        sectionButtonLink
      }
      articleSection {
        sectionTitle
        sectionDescription {
          sectionDescription
        }
        sectionButtonText
        sectionButtonLink
        subTitle
      }
    }
    allContentfulProductCategory {
      edges {
        node {
          name
          description {
            description
          }
          link
          categoryImage {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
    allContentfulArticle(sort: {fields: createdAt}) {
      edges {
        node {
          thumbnail {
            fluid {
              base64
              src
              aspectRatio
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          createdAt(fromNow: true)
          content {
            childMarkdownRemark {
              excerpt
            }
          }
          url
        }
      }
    }
    allContentfulProduct(filter: {isAProductForPpcLandingPage: {eq: true}}, sort: {fields: totalPrice, order: ASC}) {
      edges {
        node {
          id
          productName
          url
          popularLabel
          featuredProduct
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          depositPrice
          priceDescription
          productCategory {
            link
          }
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
        }
      }
    }
    featuredProducts: allContentfulProduct(filter: {featuredProduct: {eq: true}}) {
      edges {
        node {
          id
          productName
          url
          popularLabel
          featuredProduct
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          depositPrice
          priceDescription
          productCategory {
            link
          }
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
        }
      }
    }
  }
`
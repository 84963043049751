import React from 'react';
import {
  ContactUs,
  ContactUsForm,
  ContactUsLogo,
  ContactUsSection,
  FormDescription,
  Title,
  TitleBack,
  TitleSection,
  Wrapper
} from './styles'
import girlImg from '../../images/girl.png';
import RequestForm from '../RequestForm';

const PPCContactUsSection = ({ location }) => {
  return (
    <Wrapper>
      <TitleSection>
        <TitleBack>contact</TitleBack>
        <Title>Struggling to Find the Perfect Building?</Title>
      </TitleSection>

      <ContactUsSection>
        <ContactUs>
          <ContactUsLogo
            alt="Contact Us Girl"
            src={girlImg}
          />

          <ContactUsForm>
            <FormDescription>Answer a Few Questions and Let Our Building Experts Help You Choose the Ideal Metal Building</FormDescription>
            <RequestForm location={location} leftAlign />
          </ContactUsForm>
        </ContactUs>
      </ContactUsSection>
    </Wrapper>
  )
}

export default PPCContactUsSection;
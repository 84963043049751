import React from 'react';
import {
  BackgroundImg,
  BackgroundSection,
  Description,
  FinanceOption,
  FinanceOptionContent,
  FinanceOptionHead,
  FinanceOptionImg,
  FinanceOptionImgContainer,
  FinanceOptionText,
  LeftSection,
  LeftSectionContent,
  LogoContainer,
  LogoImg,
  RightDescription,
  RightSection,
  RightSectionContent,
  Title,
  Wrapper
} from './styles'
import { Link } from 'gatsby';
import DefaultButton from '../button/DefaultButton';
import rtoLogoImg from '../../images/rto-logo.png';
import logo1Img from '../../images/financeoptions/logo1.png';
import logo2Img from '../../images/financeoptions/logo2.png';
import logo3Img from '../../images/financeoptions/logo3.png';
import backImg from '../../images/finance-option-back.png'

const FinanceOptionSection = () => {
  const options = [
    {
      head: "Quick",
      text: "Say goodbye to long waits and approvals. Our financing options come with a quick approvals, so you get the building you need without delay.",
      logo: logo1Img
    },
    {
      head: "Easy",
      text: "We like to keep things simple. Our application process is straightforward, so you spend less time filling out paperwork and more time planning your new metal building.",
      logo: logo2Img
    },
    {
      head: "Transparent",
      text: "Our financing options come with clear terms and conditions , so you'll know exactly what you're getting from the start.",
      logo: logo3Img
    }
  ]

  return (
    <Wrapper>
      <LeftSection align="right">
        <LeftSectionContent>
          <Title><span>Buying</span> Your Building of Choice is Easy with Our Financing Options</Title>
          <Description>Premier Metal Structures offers several financing options that make owning a structure easy, even on a budget. We offer traditional financing plans and RTO programs that put ownership in your hands with fast approvals and no hard credit checks! <br /><br />Speak with one of our building experts at <a href="tel:8776005980"><span>(877) 600-5980</span></a> to learn more about financing.</Description>

          <Link className="btn-item">
            <DefaultButton
              backIcon
              text="Visit Here"
              icon={(<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5V10.5ZM23.0607 13.0607C23.6464 12.4749 23.6464 11.5251 23.0607 10.9393L13.5147 1.3934C12.9289 0.807611 11.9792 0.807611 11.3934 1.3934C10.8076 1.97919 10.8076 2.92893 11.3934 3.51472L19.8787 12L11.3934 20.4853C10.8076 21.0711 10.8076 22.0208 11.3934 22.6066C11.9792 23.1924 12.9289 23.1924 13.5147 22.6066L23.0607 13.0607ZM2 13.5H22V10.5H2V13.5Z" fill="white" />
              </svg>)}
            />
          </Link>
        </LeftSectionContent>
      </LeftSection>
      <RightSection>
        <BackgroundSection>
          <BackgroundImg
            alt="Finance Option"
            src={backImg}
          />
        </BackgroundSection>

        <BackgroundSection />

        <RightSectionContent>
          <LogoContainer>
            <LogoImg
              src={rtoLogoImg}
              alt="RTO logo"
            />
          </LogoContainer>
          <RightDescription>With our flexible financing options and RTO plans, owning a metal building has never been easier or more affordable!</RightDescription>

          {options.map((option, i) => (
            <FinanceOption>
              <FinanceOptionImgContainer>
                <FinanceOptionImg
                  alt={option.head}
                  src={option.logo}
                />
              </FinanceOptionImgContainer>
              <FinanceOptionContent>
                <FinanceOptionHead>{option.head}</FinanceOptionHead>
                <FinanceOptionText>{option.text}</FinanceOptionText>
              </FinanceOptionContent>
            </FinanceOption>
          ))}
        </RightSectionContent>
      </RightSection>
    </Wrapper>
  )
}

export default FinanceOptionSection;
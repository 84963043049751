import styled from 'styled-components';
import BreakpointDown from '../Media/BreakpointDown';

export const Wrapper = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: row;

  ${BreakpointDown.xl`
    margin-top: 80px;
    flex-direction: column;
  `}
  ${BreakpointDown.md`
    margin-top: 60px;
  `}
  ${BreakpointDown.md`
    margin-top: 20px;
  `}
`

export const LeftSection = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  flex: 1;
  background: #F5F6FF;
  padding-top: 60px;

  ${BreakpointDown.xxl`
    padding-left: 24px;
  `}

  ${BreakpointDown.xl`
    margin-bottom: 0;
    padding-bottom: 50px;
  `}
`

export const LeftSectionContent = styled.div`
  width: 705px;
  float: right;
  padding-right: 76px;

  ${BreakpointDown.xxl`
    width: 100%;
  `}
`

export const Title = styled.p`
  font-family: 'IBM Plex Serif';
  font-size: 38px;
  font-weight: 600;
  line-height: 49.4px;
  text-align: left;
  color: #101828;

  span {
    color: #00A21E;
  }
`

export const Description = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #444444;

  span {
    font-size: 18px;
    color: #00A21E;
  }
`

export const RightSection = styled.div`
  position: relative;
  flex: 1;
  padding-top: 50px;
  padding-bottom: 40px;

  ${BreakpointDown.xxl`
    padding-right: 24px;
  `}
`

export const RightSectionContent = styled.div`
  width: 720px;
  padding-left: 40px;

  ${BreakpointDown.xxl`
    width: 100%;
  `}
`

export const LogoContainer = styled.div`
  width: 230px;
  height: 105px;
  border-radius: 10px;
  padding: 12px 36px;
  background: white;
`

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
`

export const RightDescription = styled.p`
  margin: 0;
  margin-top: 20px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: white;
`

export const FinanceOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`

export const FinanceOptionImgContainer = styled.div`
  width: 50px;
  height: 50px;
`

export const FinanceOptionImg = styled.img`
  width: 100%;
  height: auto;
`

export const FinanceOptionContent = styled.div`
  flex: 1;
  margin-left: 20px;
`

export const FinanceOptionHead = styled.span`
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #00A21E;
`

export const FinanceOptionText = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: white;
`

export const BackgroundSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #15151CE5;
  z-index: -1;
`

export const BackgroundImg = styled.img`
  width: 100%;
  height: 100%;
`
import styled from 'styled-components';
import BreakpointDown from '../Media/BreakpointDown';

export const SpaceEmitter = styled.div`
  flex: 1;
`

export const IconBtn = styled.div`
  padding: 3px 5px;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`

export const MainContainer = styled.div`
  margin-top: -50px;
  position: relative;
  width: 100%;
  padding-bottom: 60px;
  background: #15151C;
`

export const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: #101828;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
`;

export const ForkImage = styled.img`
  position: absolute;
  top: 0;
  left: 20%;
  width: 77px;
  height: 249px;

  ${BreakpointDown.xxxl`
    left: 10%;
	`}
  ${BreakpointDown.xxl`
    left: 60px;
	`}
  ${BreakpointDown.xl`
    left: 40px;
	`}
	${BreakpointDown.lg`
    left: 20px;
	`}
	${BreakpointDown.md`
    display: none;
	`}
`

export const TitleSection = styled.div`
  position: relative;
  margin-top: 22px;
  text-align: center;
`

export const ControlBtnContainer = styled.div`
  position: absolute;
  right: 64px;
  bottom: 40px;
  width: 70px;
  display: flex;

	${BreakpointDown.md`
		right: calc(50% - 35px);
    left: calc(50% - 35px);
    bottom: 0;
	`}
	${BreakpointDown.sm`
		
	`}
`

export const TitleBack = styled.div`
  font-family: Roboto;
  font-size: 200px;
  line-height: 234.38px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  color: white;
  opacity: 0.06;

  ${BreakpointDown.xl`
    font-size: 160px;
    line-height: 192px;
	`}
	${BreakpointDown.lg`
    font-size: 120px;
	`}
  ${BreakpointDown.md`
    margin-bottom: 40px;
  `}
	${BreakpointDown.sm`
    font-size: 70px;
	`}
`

export const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center
`

export const Title = styled.h2`
  font-family: Roboto;
  font-size: 44px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: white;

  ${BreakpointDown.xl`
    font-size: 36px;
	`}
	${BreakpointDown.lg`
    font-size: 28px;
	`}
	${BreakpointDown.sm`
    font-size: 22px;
	`}
`

export const InstalledMetalBuilding = styled.div`
  margin-top: ${props => props.no % 2 === 0 ? 110 : 0}px;
  padding-bottom: 36px;

  ${BreakpointDown.lg`
  margin-top: 0
	`}
`

export const InstalledMetalBuildingImageContainer = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`

export const BuildingImage = styled.img`
  width: 100%;
  height: 100%;
`

export const NewBadge = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 87.47px;
  height: 87.47px;
`

export const InstalledMetalBuildingInfo = styled.div`
  margin-top: 20px;
  margin-left: 40px;
  color: white;

  span.sku {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    text-transform: uppercase;
  }

  span.size {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 800;
    line-height: 30px;
    text-align: left;
  }
`

export const Actions = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
`

export const CallUsBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`

export const CallUsButton = styled.div`
  cursor: pointer;
  background-color: #00A21E;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  width: 350px;
  height: 80px;
  border: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    background-color: #00b321;
  }

  &:active {
    background-color: #009a1c;
  }

  span.desc {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.75px;
    text-align: center;    
  }

  span.name {
    font-family: Roboto;
    font-size: 34px;
    font-weight: 700;
    line-height: 39.84px;
    text-align: center;    
  }
`
import styled from 'styled-components';
import BreakpointDown from '../Media/BreakpointDown';

export const Wrapper = styled.div`
    position: relative;
    margin: auto;
    margin-top: 100px;
    width: 1410px;

    ${BreakpointDown.xxl`
		width: 100%;
        padding-left: 40px;
        padding-right: 40px;
	`}

    ${BreakpointDown.lg`
        padding-left: 20px;
        padding-right: 20px;
    `}
`

export const Container = styled.div`
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${BreakpointDown.xml`
        flex-direction: column;
    `}
`

export const Background = styled.span`
    position: absolute;
    left: 0;
    top: 30px;
    font-weight: 600;
    font-family: Roboto;
    font-size: 200px;
    line-height: 142px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    opacity: 0.08;
    z-index: -1;

    ${BreakpointDown.xxl`
        font-size: 160px;
        line-height: 142px;
    `}
    ${BreakpointDown.xml`
        font-size: 200px;
        line-height: 142px;
    `}
    ${BreakpointDown.lg`
        font-size: 160px;
        line-height: 142px;
    `}

    ${BreakpointDown.md`
        font-size: 120px;
        line-height: 142px;
    `}
`

export const MainContent = styled.div`
    width: 720px;
    margin-top: 130px;

    display: flex;
    flex-direction: row;

    .content {
        flex: 1;
        margin-left: 30px;
        margin-right: 20px;

        ${BreakpointDown.md`
            margin: 0;
        `}
    }

    ${BreakpointDown.xml`
        width: 100%;
        margin-top: 100px;
    `}
`

export const LogoImageContainer = styled.div`
    width: 240px;
    height: 240px;
    background: white;
    border-radius: 10px;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 20px 0px #0000000F;

    display: flex;
    align-items: center;
    justify-content: center;

    ${BreakpointDown.md`
        display: none;
    `}
`

export const LogoImage = styled.img`
    width: 156.94px;
    height: 181.92px;
`

export const Title = styled.h2`
    font-family: Roboto;
    font-size: 44px;
    font-weight: 800;
    line-height: 51.56px;
    text-align: left;
    color: black;
    margin: 0;

    span {
        color: #00A21E
    }
`

export const Description = styled.p`
    margin: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #444444;
`

export const ReasonContainer = styled.div`
    width: 540px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;

    ${BreakpointDown.xml`
        width: 100%;
        margin-top: 50px;
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-evenly;
        height: 320px;
    `}

    ${BreakpointDown.lg`
        grid-template-columns: auto auto;
        height: 500px;
        justify-content: space-evenly;
    `}

    ${BreakpointDown.sm`
        display: flex;
        flex-direction: column;
        align-content: unset;
        align-items: center;
        height: auto;
    `}
`

export const ReasonItem = styled.div`
    width: 250px;
    height: 130px;
    padding: 20px;
    background: #EBEBFF;
    border-radius: 10px;
    margin-top: 30px;

    &:nth-child(-n + 3) {
        margin-bottom: 30px;
        margin-top: 0;
    }

    &:nth-child(4) {
        margin-top: 40px;
    }

    ${BreakpointDown.xml`
        margin-bottom: 30px !important;
        margin-top: 0 !important;
    `}
`

export const ReasonImgContainer = styled.div`
    width: 50px;
    height: 50px;
`

export const ReasonText = styled.p`
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #101828;
    margin: 0;
    margin-top: 10px;
`
import React from 'react';
import ProductSection from '../../sections/Home/ProductSection';
import {
  CardBody,
  CardFormContainer,
  CardHorizontal,
  CardTitle,
  Container,
  LeftTopSellingTitle,
  RightTopSellingTitle,
  Wrapper
} from './styles';
import RequestForm from '../RequestForm';

const SellingProductionSection = ({
  productSectionData,
  products,
  location
}) => {
  return (<Wrapper>
    <LeftTopSellingTitle>Top Selling</LeftTopSellingTitle>
    <RightTopSellingTitle>Top Selling</RightTopSellingTitle>
    <Container>
      <ProductSection
        sectionData={productSectionData}
        products={products}
        location={location}
        noCategory
      />
    </Container>

    <CardFormContainer>
      <CardHorizontal className='card-form'>
        <CardBody className='card-body'>
          <CardTitle className='card-title'>Let's Get Started. Fill out this quick form.</CardTitle>
          <RequestForm location={location} />
        </CardBody>
      </CardHorizontal>
    </CardFormContainer>
  </Wrapper>)
}

export default SellingProductionSection;